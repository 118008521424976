import React from 'react'
import './styles.css'
import Resume from './chandlerwheatmartinresume.pdf'

function Home() {

  const handleDownload = () => {
    fetch(Resume)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'chandlerwheatmartinresume.pdf'); // Set the desired filename
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
      });

  };
    return (
      <div className="home-container">
        <div className="home-content">
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', paddingTop: '150px' }}>
            <h1 style={{ textAlign: 'right', margin: '0', padding: '0', color: 'white', fontSize: '150px'}}>
              Hello
              <span style={{ color: '#b6ff9d' }}>,</span>
            </h1>
            <h1 style={{ textAlign: 'right', margin: '0', padding: '0', color: 'white', fontSize: '150px' }}>
              I
              <span style={{ color: '#b6ff9d' }}>'</span>
              m Chandler
              <span style={{ color: '#b6ff9d' }}>.</span>
            </h1>
            <h1 style={{ textAlign: 'right', margin: '0', paddingTop: '30px', color: 'white', fontSize: '70px', opacity: '50%' }}>
              Software
              <span style={{ color: '#b6ff9d', opacity: '100%' }}> & </span>
              Web Developer
            </h1>
            <button onClick={handleDownload} className="resume-btn" style={{ fontSize: '30px'}}>
              Download Resume
            </button>
          </div>
        </div>
      </div>
    );
  }
  
  export default Home;

