import logo from './logo.svg';
import React, { useState } from 'react';
import './App.css'
import './components/styles.css'
import Terminal from './components/Terminal'
import {Sidebar as ProSidebar, Menu, MenuItem} from "react-pro-sidebar";
import { BrowserRouter as Router, Route, Routes, Link, NavLink, Switch, useLocation } from 'react-router-dom';
import Home from './components/Home'
import Projects from './components/Projects'
import About from './components/About'
import Experience from './components/Experience'
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';

function App() {

  const MenuItem = ({ to, icon, label }) => {
    const location = useLocation();
    const isActive = location.pathname === to;

    return (
      <button className="menu">
        <Link to={to} className="Link">
          {isActive ? <span className="menu-text">{label}</span> : <span className="menu-icon">{icon}</span>}
        </Link>
      </button>
    );
  };
  
  return (
    <Router>
      <div className="app-container" style={{backgroundColor: '#222222'}}>
           <div style={{width: '30vh', height: '100vh', position: '-webkit-sticky', position: 'sticky', top: 0, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <MenuItem to="/" icon={<HomeOutlinedIcon />} label="Home" />
              <MenuItem to="/projects" icon={<WorkOutlineOutlinedIcon />} label="Projects" />
              <MenuItem to="/experience" icon={<SchoolOutlinedIcon />} label="Experience" />
              <MenuItem to="/about" icon={<HelpOutlineOutlinedIcon />} label="About" />
            </div>
           
        

        <div className="main-content">
          <Routes>
              <Route path="/" element={<Home/>} /> {/* Home Route */}
              <Route path="/projects" element={<Projects/>} /> {/* Projects Route */}
              <Route path="/experience" element={<Experience/>} /> {/* Experience Route */}
              <Route path="/about" element={<About/>} /> {/* About Route */}
          </Routes>
        </div>

      </div>
      
    </Router>
  );
}

export default App;
