import React from 'react';
import './styles.css';
import UNR from './unr-logo.png';
import CoinCloud from './coincloud-logo.png';
import Bently from './bently-logo.png';
import './styles.css';

function Experience() {
  return (
    <div className="experience-container">

      <div className="experience-card">
        <div className="experience-card-content">
            <h1 className="experience-job-title">University of Nevada, Reno</h1>
            <h2 className="experience-position">Computer Science</h2>
            <p className="experience-description">Major in Computer Science and Engineering with a Minor in Mathematics.</p>
        </div>
        <div className="experience-card-image">
          <img style={{width: "30%", paddingTop: "50px"}} src={UNR} alt="University of Nevada, Reno Logo" className="experience-logo" />
        </div>
      </div>

      <div className="experience-card">
        <div className="experience-card-content">
          <h1 className="experience-job-title">Coin Cloud</h1>
          <h2 className="experience-position">Research Assistant</h2>
          <p className="experience-description">Developed the Solidity Smart Contract for Coin Cloud's internal crypto payroll system.</p>
        </div>
        <div className="experience-card-image">
          <img src={CoinCloud} alt="Coin Cloud Logo" className="experience-logo" />
        </div>
      </div>

      <div className="experience-card">
        <div className="experience-card-content">
          <h1 className="experience-job-title">Bently Nevada</h1>
          <h2 className="experience-position">Software Engineer</h2>
          <p className="experience-description">Worked alongside a team of 3 other full stack engineers to develop a recreational equipment rental system.</p>
        </div>
        <div className="experience-card-image">
          <img src={Bently} alt="Bently Nevada Logo" className="experience-logo" />
        </div>
      </div>

      
    </div>
  );
}

export default Experience;
