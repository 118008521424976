import React from 'react'
import Resume from './chandlerwheatmartinresume.pdf'
import GitHubIcon from '@mui/icons-material/GitHub';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
// import DiscordIcon from '@mui/icons-material/Discord';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord } from '@fortawesome/free-brands-svg-icons'

function About() {

    const handleDownload = () => {
        fetch(Resume)
          .then(response => response.blob())
          .then(blob => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'chandlerwheatmartinresume.pdf'); // Set the desired filename
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
          });
      };

      return (
        <div className="about-container">
          <div className="about-content">
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', paddingTop: '200px', paddingRight: '50px' }}>
              <h1 style={{ textAlign: 'right', margin: '0', padding: '0', color: 'white', fontSize: '100px'}}>
                <span style={{ color: '#b6ff9d' }}>"</span>
                  Creating quality products
              </h1>
              <h1 style={{ textAlign: 'right', margin: '0', padding: '0', color: 'white', fontSize: '100px'}}>
                  that see real world use.
                <span style={{ color: '#b6ff9d' }}>"</span>
              </h1>
              <div style={{ display: 'flex'}}>

                <div className="socials-button-container">
                  <button className="github-icon-about" >
                    <a href="https://github.com/chandlermar">
                      {<GitHubIcon style={{color: "white", fontSize: "50px"}}/>}
                    </a>
                  </button>

                  <button className="linkedin-icon-about" style={{paddingLeft: "0px"}}>
                    <a href="https://www.linkedin.com/in/chandlerwheatmartin/">
                      {<LinkedInIcon style={{color: "white", fontSize: "50px"}}/>}
                    </a>
                  </button>

                  <button className="discord-icon-about">
                    <a href="https://discordapp.com/users/261705022176231426">
                      <FontAwesomeIcon icon={faDiscord} style={{ color: "white", fontSize: "40px" }} />
                    </a>
                  </button>

                  <button className="youtube-icon-about">
                    <a href="https://www.youtube.com/channel/UCbQ08jtbSLgSSXPbUKYHcOQ">
                      {<YouTubeIcon style={{color: "white", fontSize: "50px"}}/>}
                    </a>
                  </button>
                </div>

                <div className="resume-button-container">
                  <button onClick={handleDownload} className="resume-btn" style={{ fontSize: '30px'}}>
                        Download Resume
                  </button>
                </div>
                
              </div>

              

            </div>
          </div>

          </div>
      );
  
}

export default About;
