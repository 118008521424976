import React from 'react';
import './styles.css'
import Bently from './bentlygif-optimized.gif'
import CrowsSpeak from './crowsspeak-optimized.gif'
import ParticleSimulator from './particlesimulator-optimized.gif'
import WheatAI from './wheatai-optimized.gif'
import Multicade from './multicade-optimized.gif'
import GreenLine from './Rectangle 1.png'
import GitHubIcon from '@mui/icons-material/GitHub';
import YouTubeIcon from '@mui/icons-material/YouTube';

const Projects = () => {
  return (
    <div className="project-container">
        
        <div class="grid-container">

        <div class="box-left">
                <div className="corner-wrap">
                    <div className="corner">
                        <a href="https://www.youtube.com/watch?v=NwPPsFUcgYY">
                            <img src={Multicade} alt="Multicade" autoPlay muted />
                        </a>
                    </div>
                </div>
                
                <h1 className="particle-sim-header" style={{fontSize: "50px"}}>Casino Multicade
                    <span style={{ color: '#b6ff9d' }}> - </span>
                    Oct 2023

                    <h2 style={{fontSize: "30px"}}> Casino Multicade containing Poker and Blackjack that
                    <span style={{ color: '#b6ff9d' }}> emulates </span> 
                    a tabletop gaming experience
                        <span style={{ color: '#b6ff9d' }}> . </span>   
                    </h2>
                    <div className="social-project-links">
                        <button className="github-icon">
                            <a href="https://github.com/chandlermar/Multicade/">
                                {<GitHubIcon style={{color: "white", fontSize: "50px"}}/>}
                            </a>
                        </button>
                        <button className="youtube-icon">
                            <a href="https://www.youtube.com/watch?v=NwPPsFUcgYY">
                                {<YouTubeIcon style={{color: "white", fontSize: "50px"}}/>}
                            </a>
                        </button>
                    </div>
                 </h1>
            </div>

            <div class="box-left">
                <div className="corner-wrap">
                    <div className="corner">
                        <a href="https://www.youtube.com/watch?v=lQa6mx14wNw">
                            <img src={ParticleSimulator} alt="Particle Simulator" autoPlay muted />
                        </a>
                    </div>
                </div>

                {/* <div className="center-decor-container">
                    <img src={GreenLine} class="centerDesign"/>
                </div> */}

                <h1 className="particle-sim-header" style={{fontSize: "50px"}}>Particle Simulator
                    <span style={{ color: '#b6ff9d' }}> - </span>
                    Jan 2023

                    <h2 className="particle-sim-body" style={{fontSize: "30px"}}>VR experience that allows users to explore emergent behavior regarding the simple rules that 
                    <span style={{ color: '#b6ff9d' }}> parameterize </span>
                     particle interactions
                    <span style={{ color: '#b6ff9d' }}> . </span>
                    </h2>
                    <div className="social-project-links">
                        <button className="github-icon">
                            <a href="https://github.com/chandlermar/vr-final-project">
                                {<GitHubIcon style={{color: "white", fontSize: "50px"}}/>}
                            </a>
                        </button>
                        <button className="youtube-icon">
                            <a href="https://www.youtube.com/watch?v=lQa6mx14wNw">
                                {<YouTubeIcon style={{color: "white", fontSize: "50px"}}/>}
                            </a>
                        </button>
                    </div>
                 </h1>
                
            </div>

            <div class="box-left">
                <div className="corner-wrap">
                    <div className="corner">
                        <a href="https://www.youtube.com/watch?v=t7-w-nWRGpc&t=386s">
                            <img src={Bently} alt="Bently Adventures" autoPlay muted />
                        </a>
                    </div>
                </div>
                
                <h1 className="particle-sim-header" style={{fontSize: "50px"}}>Bently Adventures
                    <span style={{ color: '#b6ff9d' }}> - </span>
                    May 2023

                    <h2 style={{fontSize: "30px"}}>Senior Capstone Project advised by Bently Nevada. Worked with a team of peers to 
                    <span style={{ color: '#b6ff9d' }}> develop </span> 
                    an outdoor gear reservation system for Bently employees
                        <span style={{ color: '#b6ff9d' }}> . </span>   
                    </h2>
                    <div className="social-project-links">
                        <button className="github-icon">
                            <a href="https://github.com/Jasonbrown7/Inventory_Management_System">
                                {<GitHubIcon style={{color: "white", fontSize: "50px"}}/>}
                            </a>
                        </button>
                        <button className="youtube-icon">
                            <a href="https://www.youtube.com/watch?v=t7-w-nWRGpc&t=386s">
                                {<YouTubeIcon style={{color: "white", fontSize: "50px"}}/>}
                            </a>
                        </button>
                    </div>
                 </h1>
            </div>

            <div class="box-left">

                <div className="corner-wrap">
                    <div className="corner">
                        <img src={CrowsSpeak} alt="The Crows Speak" autoPlay muted />
                    </div>
                </div>

                <h1 className="crows-speak-header" style={{fontSize: "50px"}}>The Crows Speak
                    <span style={{ color: '#b6ff9d' }}> - </span>
                    Jun 2023

                    <h2 style={{fontSize: "30px"}}>PSX Horror game that tells the story of a lone farmer 
                    <span style={{ color: '#b6ff9d' }}> unveiling </span>
                     the mysteries of the island's wildlife
                    <span style={{ color: '#b6ff9d' }}> . </span>
                    </h2>
                    <div className="social-project-links">
                        <button className="github-icon">
                            <a href="https://github.com/chandlermar/the-crows-speak">
                                {<GitHubIcon style={{color: "white", fontSize: "50px"}}/>}
                            </a>
                        </button>
                    </div>
                 </h1>
            </div>

            <div class="box-left">

                <div className="corner-wrap">
                    <div className="corner">
                        <a href="https://www.youtube.com/watch?v=UdPobYM2hRs&t=12s">
                            <img src={WheatAI} alt="WheatAI Chatbot" autoPlay muted />
                        </a>
                    </div>
                </div>

                <h1 className="crows-speak-header" style={{fontSize: "50px"}}>Wheat AI
                    <span style={{ color: '#b6ff9d' }}> - </span>
                    Sep 2023

                    <h2 style={{fontSize: "30px"}}>Personal chatbot that can respond to your queuries 
                    <span style={{ color: '#b6ff9d' }}> speaking </span>
                     as a clone of my own voice
                    <span style={{ color: '#b6ff9d' }}> . </span>
                    </h2>
                    <div className="social-project-links">
                        <button className="github-icon">
                            <a href="https://github.com/chandlermar/Personal-Chatbot">
                                {<GitHubIcon style={{color: "white", fontSize: "50px"}}/>}
                            </a>
                        </button>
                        <button className="youtube-icon">
                            <a href="https://www.youtube.com/watch?v=UdPobYM2hRs">
                                {<YouTubeIcon style={{color: "white", fontSize: "50px"}}/>}
                            </a>
                        </button>
                    </div>
                 </h1>
            
            </div>
        </div>
    </div>
  );
};

export default Projects;