import React from 'react'
import monospaceStyle from './styles.css'

function Prompt() {

  return (
    <div>
        <pre style={monospaceStyle}>
             _____ _                     _ _           _       _____           _    __      _ _       
            /  ____| |                   | | |         ( )     |  __ \         | |  / _|    | (_)      
            | |    | |__   __ _ _ __   __| | | ___ _ __|/ ___  | |__) |__  _ __| |_| |_ ___ | |_  ___  
            | |    | '_ \ / _` | '_ \ / _` | |/ _ \ '__| / __| |  ___/ _ \| '__| __|  _/ _ \| | |/ _ \ 
            | |____| | | | (_| | | | | (_| | |  __/ |    \__ \ | |  | (_) | |  | |_| || (_) | | | (_) |
            \_____|_| |_|\__,_|_| |_|\__,_|_|\___|_|    |___/ |_|   \___/|_|   \__|_| \___/|_|_|\___/ 
        </pre>                                                                     
                                                                                           
    </div>
  )
}

export default Prompt
